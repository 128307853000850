import React, { Component } from "react";

import Helmet from "react-helmet";

import styled from "styled-components";
import { Row, Col } from "react-grid-system";

import TitleContainer from "components/Elements/TitleContainer";
import TitleHeader from "components/Elements/TitleHeader";

const SubQuote = styled.h1`
  color: #333;
  font-size: 30px;
  padding: 0;
  margin: 0;
`;

class Pro extends Component {
  render() {
    return (
      <>
        <Helmet title="Join the community | Enlight" />
        <TitleContainer>
          <br />
          <TitleHeader className="playfair">Join the community</TitleHeader>
          <br />
          <SubQuote>

          Level up your learning with a paid plan.
          </SubQuote>
          <br />
          <br />
        </TitleContainer>

        <Row>
        <Col sm={6}>
            Subscriber
        </Col>
        <Col sm={6}>
            Maker
        </Col>
        </Row>
      </>
    );
  }
}

export default Pro;
